import { useEffect, useState } from "react";
import axios from "axios";

import { PackageType } from "@lib/data/schemas/packages";

type UsePackageTotalPriceParams = (packageItem: PackageType) => {
  total: number;
};

const usePackageTotalPrice: UsePackageTotalPriceParams = (packageItem) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (packageItem.paymentOptions.length !== 1) return;

    const calculateTotal = async () => {
      const response = await axios.post("/api/v1/calculate-package-payment", {
        amount: packageItem.paymentOptions[0].amount,
        currency: packageItem.paymentOptions[0].currency,
        taxTypeId: packageItem.paymentOptions[0].taxTypeId,
        splits: packageItem.paymentOptions[0].splits,
      });
      setTotal(response?.data?.total || 0);
    };

    calculateTotal();
  }, [packageItem.paymentOptions]);

  return {
    total,
  };
};

export default usePackageTotalPrice;
