import React, { FC } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

import { SVGIconProps } from "@lib/shared-types";

import { Button } from "@components/Button";

export interface ToggleOption {
  label: string;
  icon?: FC<SVGIconProps>;
  value: string;
}

export interface ToggleSelectorProps {
  selected: string;
  onChange: (value: string) => void;
  options: ToggleOption[];
  className?: string;
  size?: "small" | "smaller";
}

export function ToggleSelector({
  onChange,
  selected,
  options,
  className,
  size,
}: ToggleSelectorProps) {
  const renderItem = (
    value: string,
    label: string,
    Icon?: FC<SVGIconProps>
  ) => {
    const isActive = value === selected;
    return (
      <Button
        id={`toggle-selector-option-${value}`}
        key={`toggle-selector-option-${value}`}
        smaller={size === "smaller"}
        small={size === "small"}
        className={classNames(
          "toggle-selector-option",
          "flex-1 bg-transparent font-normal text-md text-grey-500 hover:bg-transparent hover:text-grey-300 active:bg-white",
          isActive &&
            "shadow-elevation-50 toggle-option-active bg-white cursor-pointer text-black-ink hover:text-black-ink hover:bg-white"
        )}
        onClick={() => onChange(value)}
        circle
      >
        {Icon && <Icon className="h-5 w-5 flex-none" />}
        <h3>{label}</h3>
      </Button>
    );
  };

  return (
    <div className={twMerge("flex rounded-3xl bg-grey-950 p-0.5", className)}>
      <input className="toggle-selector-value" type="hidden" value={selected} />
      {options.map((opt) => renderItem(opt.value, opt.label, opt.icon))}
    </div>
  );
}
