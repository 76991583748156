import { FC, ReactNode } from "react";
import classNames from "classnames";

import { AccountType } from "@lib/data/schemas/account";
import { PackageType } from "@lib/data/schemas/packages";

import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";
import TypeIllustration from "@components/TypeIllustration";

import { getPackageSubtitle } from "./Package/utils";
import AccountDetail from "./PublicProfile/AccountDetail";
import { ConditionalTeamsRenderer } from "./ConditionalTeamsRenderer";

type PackageItemProps = {
  title: string;
  subtitle: string;
  icon: string;
  accounts?: AccountType[];
};

export const PackageItem: FC<PackageItemProps> = ({
  icon,
  title,
  subtitle,
  accounts,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <TypeIllustration slug={icon} />
        <div className="pl-4">
          <div>{title}</div>
          <div className="flex items-center text-sm text-grey-500 mt-1">
            <ConditionalTeamsRenderer>
              <AccountDetail accounts={accounts} hideNames />
            </ConditionalTeamsRenderer>
            <span className={classNames(accounts && "pl-2")}>{subtitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PackagePickerProps {
  className?: string;
  packages: (PackageType & { accounts: AccountType[] })[];
  onPackageChange: (value: PackageType) => void;
  selectedPackage?: PackageType;
  label?: string | null;
  showIcon?: boolean;
  customPlaceholder?: ReactNode;
}

const PackagePicker: FC<PackagePickerProps> = ({
  className,
  packages: allPackages,
  onPackageChange,
  selectedPackage,
  label = "Package",
  showIcon = false,
  customPlaceholder,
}) => {
  const { filteredOptions: packages, searchProps } = useSimpleSearchProps(
    allPackages,
    "Search packages..."
  );
  return (
    <div className={classNames("mt-4", className)}>
      <Listbox
        searchProps={searchProps}
        customListboxClassNames="!shadow-none"
        onChange={(packageItem: PackageType) => onPackageChange(packageItem)}
        label={label}
        placeholder={
          (selectedPackage &&
            (showIcon ? (
              <PackageItem
                {...selectedPackage}
                subtitle={getPackageSubtitle(selectedPackage)}
              />
            ) : (
              selectedPackage.title
            ))) ||
          (customPlaceholder ?? "Select a package")
        }
        value={selectedPackage}
        customAddButton={!!customPlaceholder}
        customMenuWrapperClassNames="bg-white"
      >
        {packages?.map((packageItem, index) => {
          const { title, icon, accounts } = packageItem;
          return (
            <div key={index}>
              <ListboxOption value={packageItem} primaryText={title}>
                <PackageItem
                  title={title}
                  icon={icon}
                  subtitle={getPackageSubtitle(packageItem)}
                  accounts={accounts}
                />
              </ListboxOption>
            </div>
          );
        })}
      </Listbox>
    </div>
  );
};

export default PackagePicker;
